import React, { Component } from "react";
import { Route, Routes, Redirect } from "react-router-dom";
import Login from "./components/pages/Login/Login";
import VehicleRentalForm from "./components/pages/Register-Foreign/VehicleForm";
// import { Form, Input, Button, Select, TimePicker } from "antd";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  TimePicker,
  Card,
} from "antd";
import VehicleForm from "./components/pages/Register-Foreign/VehicleForm";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./components/header/header";
import Car from "./components/pages/Sinister/Car";
// import { Routes, Route } from "react-router";

const { Option } = Select;
// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={(props) =>
//       isAuthenticated() ? (
//         <Component {...props} />
//       ) : (
//         <Redirect
//           to={{
//             pathname: "/login",
//             state: { from: props.location },
//           }}
//         />
//       )
//     }
//   />
// );

function App() {
  return (
    <div>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/894kjn9ui6798vu" element={<VehicleForm />} />
          <Route path="/" element={<Car />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
