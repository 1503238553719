import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Card,
  Row,
  Col,
  Typography,
  Divider,
  InputNumber,
} from "antd";
import logo from "../../assets/images/logo.png"
import { Upload } from 'antd';

import "./car.css"

const { Option } = Select;

const layout = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 16 },
};


const countries = [
  { code: "US", label: "+1 United States" },
  { code: "GB", label: "+44 United Kingdom" },
  { code: "BR", label: "+55 Brazil" },
  // Adicionar outros países
];


function Car() {

  const [form] = Form.useForm();
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);
  const [, forceUpdate] = useState();

  const onFinish = values => {
    console.log('Success:', values);
  };


  const [fileList, setFileList] = useState([
    
  ]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div>
      <Row
        type="flex"
        style={{
          alignItems: "center",
        }}
        justify="center"
        block
      >

        {/* <Col xs={20} sm={16} md={12} lg={12} xl={12}> */}
        <Col xs={21} xl={18}>

          <Typography
            style={{ width: '100%', textAlign: "center", justifyContent: 'center' }}
          >
            <Title level={5}>
              <br />
              <div className="logo-container">
                <img src={logo} />
              </div>
            </Title>
          </Typography>

          <Divider  >
            <b>Participação de Sinistro</b>
          </Divider>
          <br />

          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              name="full_name"
              label="Nome Completo"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="policy_number"
              label="Número da Apólice do Seguro"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='country_code'
              label="Código do país"
              rules={[
                {
                  required: true,
                },
              ]}
            >

              <Select placeholder="Código do país" disabled={isLoading} allowClear>
                <Option value="+27">&nbsp;+27 &nbsp;| África do sul</Option>
                <Option value="+255">+255 | Tanzânia</Option>
                <Option value="+258">+258 | Moçambique</Option>
                <Option value="+260">+260 | Zâmbia</Option>
                <Option value="+263">+263 | Zimbábue</Option>
                <Option value="+265">+265 | Malawi</Option>
                <Option value="+268">+268 | Eswatini</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Contacto Telefónico"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: "100%" }} block />
            </Form.Item>

            <Form.Item

              name='upload_picture'
              label="Carregue a foto"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              {/* <ImgCrop rotate> */}
              <Upload
                block
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList.length < 6 && '+ Fotografia'}
              </Upload>
              {/* </ImgCrop> */}
            </Form.Item>

            <Form.Item >
              <br />
              <Button type="primary" htmlType="submit" block >
                Registar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Car;
