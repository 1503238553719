import React, { useState } from "react";
import { Form, Input, Select, Button } from "antd";

const { Option } = Select;

const Login = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState({});

  const onFinish = (values) => {
    setUser(values);
    form.resetFields();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Nome"
        rules={[{ required: true, message: "Por favor insira o seu nome!" }]}
      >
        <Input placeholder="Insira o seu nome" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            type: "email",
            message: "O email inserido não é válido!",
          },
          {
            required: true,
            message: "Por favor insira o seu email!",
          },
        ]}
      >
        <Input placeholder="Insira o seu email" />
      </Form.Item>

      <Form.Item name="gender" label="Gênero">
        <Select placeholder="Selecione o seu gênero">
          <Option value="male">Masculino</Option>
          <Option value="female">Feminino</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Limpar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
