import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  TimePicker,
  Card,
  Row,
  Col,
  Typography,
  Divider,
} from "antd";

function VehicleForm() {
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);
  const { Title } = Typography;
  const [isLoading, setIsLoading] = useState(false);

  const vehicleTypes = [
    { value: "light", label: "Ligeiro" },
    { value: "heavy", label: "Pesado" },
    { value: "trailer", label: "Atrelados" },
    { value: "motorcycle", label: "Motociclos" },
  ];

  const Borders = [
    { value: "Ressano Garcia", label: "Ressano Garcia" },
    { value: "Goba", label: "Goba" },
    { value: "Ponta do ouro", label: "Ponta do ouro" },
  ];

  const calculateTotal = (values) => {
    // cálculo para calcular o total a pagar

    setTotal(200);
    console.log(values);
  };

  function payInsurance(values) {
    const data = {
      name: values.name,
      registration: values.registration,
      brand: values.brand,
      starter_date: values.starter_date,
      end_date: values.end_date,
      vehicle_type: values.vehicle_type,
      border_name: values.border_name,
      amount_pay: values.amount_pay,
    };
  }

  return (
    <div>
      <Row
        type="flex"
        style={{
          alignItems: "center",
        }}
        justify="center"
        block
      >
        <Col xs={20} sm={16} md={12} lg={12} xl={12}>
          <Typography>
            <Title level={5}>
              SEGURO AUTOMÓVEL - RESPONSABILIDADE CIVIL TURISTAS | TOURIST AUTO
              COVER N: 38739
            </Title>
          </Typography>

          <Form
            form={form}
            onFinish={calculateTotal}
            initialValues={{ remember: false }}
            disabled={isLoading}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Segurador/Joured"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Divider orientation="left" plain>
              <b>Veículo Seguro/Vehicle details</b>
            </Divider>
            <Form.Item
              label="Matrícula/Registration"
              name="registration"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Marca/Modelo | Make and Model"
              name="brand"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Divider orientation="left" plain>
              <b>Duração do seguro (Válido por 30 dias/Duration 30 days)</b>
            </Divider>
            <Form.Item
              label="Início às"
              name="starter_date"
              rules={[{ required: true }]}
            >
              <TimePicker format="HH:mm" /> horas do dia
              <DatePicker showTime format="DD-MM-YYYY" />
            </Form.Item>
            <Form.Item
              label="Termina às 24:00 horas do dia"
              name="end_date"
              rules={[{ required: true }]}
            >
              <DatePicker showTime format="DD-MM-YYYY" />
            </Form.Item>
            <Divider orientation="left" plain>
              <b>Prémio Total</b>
            </Divider>
            <Form.Item
              label="Tipo de Veículo"
              name="vehicle_type"
              rules={[{ required: true }]}
            >
              <Select options={vehicleTypes} />
            </Form.Item>

            <Card elevation={2}>
              <p>TOTAL A PAGAR: {total}</p>
            </Card>
            <Divider orientation="left" plain>
              <b>Angariador/Agent</b>
            </Divider>
            <Form.Item
              label="Fronteira de"
              name="border_name"
              rules={[{ required: true }]}
            >
              <Select options={Borders} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Calcular Total
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default VehicleForm;
